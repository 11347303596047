// @flow

import { Button, Typography } from '@material-ui/core';
import {
  MenuApi,
  MenuCloud,
  MenuDashboard,
  MenuManagement,
  MenuMobile,
  MenuDevSecOps,
  MenuSupplyChainSecure,
  MenuSupplyChainSecurity,
  MenuWeb,
  MenuScs,
} from '@dt/material-components/navigation/ConfigurableLHSMenuConfiguration';
import {
  NotFoundPage,
  OnboardingBanner as OnboardingBannerComponent,
} from '@dt/components';
import { Actions as NotificationsActions, Notifier } from '@dt/notifications';
import React, { Suspense, lazy, memo, useCallback, useEffect } from 'react';
import { Redirect, Router, useLocation } from '@reach/router';
import {
  accessControlsApi,
  accessControlsLandingPage,
  accessControlsShare,
  userManagementControlsApi,
} from './redux/access_controls';
import { useDispatch, useSelector } from 'react-redux';

import { default as ConfigurableLHSMenuComponent } from '@dt/material-components/navigation/ConfigurableLHSMenuPage';
import { Raven } from '@dt/global';
import type { State } from './redux/store_state_type';
import { entries } from 'lodash/fp';
import { makeStyles } from '@material-ui/styles';
import { palette } from '@dt/theme';
import { useSession } from './session';
import { BillingReminderSnack } from '@dt/billing-info';

/*
 * Dynamically load a page component import to provide users only what they're asking for on
 * a page by page basis.
 *
 * Generated chunks aren't always one-to-one to their corresponding dynamic import.
 *
 * @param chunkImport - Dynamic import to handle errors and app upgrades for.
 */
const chunk = chunkImport => {
  return chunkImport
    .then(response => {
      // On succes - reset application update reload attempts.
      if (localStorage) {
        localStorage.setItem(
          'applicationUpdateReloadAttempts',
          JSON.stringify(0),
        );
      }

      return response;
    })
    .catch(e => {
      // User is most likely using an outdated version of the application.
      // Check to see if a new application version was released and attempt to automatically
      // reload.
      //
      // NOTE: This is only *okay* because we chunk by "page" meaning that
      //       when a user is navigating to a new page they might be looking for old files.
      //       So we manually "upgrade" them.
      //
      //       There are other better ways to fix this. But that implies changing our
      //       deployment pipelines and hosting provider.
      //
      //       Alternative Methods:
      //
      //       1. Keep the old application chunks around for a period of time.
      //          Which means keeping these chunks at the same deployment site.
      //
      //       2. Keep track of the application version and host these chunks in
      //          reliable locations that won't change when a new application is
      //          deployed. Using the version we could then reference which chunks
      //          to load for that version.
      //
      if (/Loading chunk [\d]+ failed/.test(e.message)) {
        // Attempt to reload the application automatically.
        if (localStorage) {
          const appUpdateReloadAttempts =
            JSON.parse(
              localStorage.getItem('applicationUpdateReloadAttempts') || '0',
            ) + 1;
          localStorage.setItem(
            'applicationUpdateReloadAttempts',
            JSON.stringify(appUpdateReloadAttempts),
          );

          // If this keeps happening something is wrong.
          if (appUpdateReloadAttempts >= 3) {
            Raven.captureException(
              new Error('User is unable to load application chunks.'),
              {},
            );

            throw e;
          } else {
            window.location.reload();

            // Application updates are expected and therfore shouldn't error to the user.
            // It might be a better user experience to indicate to the user an upgrade needs
            // to happen.
            return {
              default: function() {
                return null;
              },
            };
          }
        } else {
          // Browser has `localStorage` disabled.
          return {
            default: function() {
              return (
                <div
                  style={{
                    height: '100%',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    flexDirection: 'column',
                  }}
                >
                  <Typography variant="body1" style={{ textAlign: 'center' }}>
                    A new version of the application is available. Please reload
                    to continue.
                  </Typography>
                  <Button
                    variant="contained"
                    color="primary"
                    onClick={() => window.location.reload()}
                  >
                    Reload
                  </Button>
                </div>
              );
            },
          };
        }
      } else {
        throw e;
      }
    });
};

// prettier-ignore
const {
  ApiAssetsUuidPage,
  ApiAsmSetupPage,
  ApiAssetGroupsUuidPage,
  ApiInventoryPage,
  ApiPoliciesPage,
  ApiPoliciesUuidPage,
  ApiPolicyViolationsPage,
  ApiPolicyViolationsUuidPage,
  ApiCloudResourcesUuidPage,
  APINetworkServicesUuidPage,
  ApiMobileApplicationLoadingPage,
  ApiProtectionPage,
  ApiProtectUuidPage,
  ApiShadowAssetsPage,
  ApiWebApplicationsUuidPage,
  ApiGraphqlApisUuidPage,
  ApiCustomChecksPage,
  CloudActivityPage,
  CloudAsmSetupPage,
  CloudAssetGroupsUuidPage,
  CloudCloudResourcesUuidPage,
  CloudInventoryPage,
  CloudNetworkServicesUuidPage,
  CloudPage,
  CloudAssetsUuidPage,
  CloudPoliciesPage,
  CloudPoliciesUuidPage,
  CloudPolicyViolationsPage,
  CloudPolicyViolationsUuidPage,
  CloudShadowAssetsPage,
  CloudToolkitsCodeRedPage,
  CloudToolkitsDlpPage,
  CloudToolkitsGotchasPage,
  CloudToolkitsLeaksPage,
  CloudToolkitsRansomwarePage,
  CloudToolkitsAlphaSOCPage,
  CloudToolkitsPage,
  CloudWebApplicationsUuid,
  CloudGraphqlApisUuidPage,
  CloudIacScanPage,
  DashboardPage,
  MajorMalfunctionPage,
  ManagementSharePolicyViolationPage,
  WebApplicationsUuidPage,
  WebAssetsUuidPage,
  WebAsmSetupPage,
  WebAssetGroupsUuidPage,
  WebCloudResourcesUuidPage,
  WebGraphqlApisUuidPage,
  WebInventoryPage,
  WebPage,
  WebPoliciesPage,
  WebPoliciesUuidPage,
  WebPolicyViolationsPage,
  WebPolicyViolationsUuidPage,
  WebNetworkServicesUuidPage,
  WebSecurityToolkitsDetectInjectPage,
  WebSecurityToolkitsPage,
  WebSecurityToolkitsXssAttackPage,
  WebSecurityToolkitsXssProtectionPage,
  WebSecurityToolkitsWebProtectPage,
  WebShadowAssetsPage,
  WebToxicTokensPage,
  SupplyChainPage,
  SupplyChainSBOMPage,
  SupplyChainVendorsPage,
  SupplyChainMyAppsPage,
  SupplyChainAsmSetupPage,
  SupplyChainSbomSecurityIssuesPage,

  // {{ TODO: These need to be broken out into separate pages.
  AtlasPublicAppSecurity,
  AtlasConfiguration,
  AtlasApplicationView,
  AtlasSearchView,
  ApolloActivityDashboardContainer,
  ApolloApiOperationDetailsContainer,
  ApolloCorrectAndProjectContainer,
  ApolloDetectInjectContainer,
  ApolloHackExtractContainer,
  ApolloLeakyApisContainer,
  ApolloOverview,
  ApolloOverviewDetail,
  ApolloPolicyRuleWithViolationsAndDetails,
  ApolloSharedLinks,
  ApolloSsrfSploitsContainer,
  ManagementActivity,
  ManagementComplianceReports,
  ManagementLandingPage,
  ManagementProductOnboardingContainer,
  ManagementProfile,
  ManagementUserDashboard,
  ManagementUserEdit,
  ManagementUserInvite,
  ManagementVendorManagementContainer,
  OnboardingWizardPage,
  OnboardingWizardPublicViewPage,
  ShareCloudResourcePublicView,
  ShareNetworkServicesPublicView,
  ShareOnboardingPublicView,
  ShareProductOnboardingWizardPublicView,
  ShareRestfulAPIPublicView,
  ShareWebAppPublicView,
  // }}
  
  // Mobile secure
  MobilePoliciesPage,
  MobilePoliciesUuidPage,
  MobileInventoryPage,
  MobileAssetGroupsUuidPage,
  
  SlackMicrosoftTeamsIntegrationPage,
  WebhookIntegrationPage,
  SamlIntegrationPage,

  // Shared policy violations
  SharedPolicyViolations,

  // DevSecOps
  DevSecOpsPage,
  StanfordDishUniHome,
  StanfordDishUniViewAllMaterials,
  DevSecOpsJiraApiCloudWebLandingPage,
  DevSecOpsJiraApiCloudWebConfigure,

  // SCS
  VendorUuidPage,
} = {
  ApiCustomChecksPage:                        lazy(() => chunk(import('./pages/api/toolkits/custom-checks/CustomChecksPage'))),
  ApiAssetsUuidPage:                          lazy(() => chunk(import('./pages/api/assets/uuid/ApiAssetsUuidPage'))),
  ApiAsmSetupPage:                            lazy(() => chunk(import('./pages/api/asm_setup/ApiAsmSetupPage'))),
  ApiAssetGroupsUuidPage:                     lazy(() => chunk(import('./pages/api/asset_groups/uuid/ApiAssetGroupsUuidPage'))),
  ApiInventoryPage:                           lazy(() => chunk(import('./pages/api/inventory/ApiInventoryPage'))),
  ApiPoliciesPage:                            lazy(() => chunk(import('./pages/api/policies/ApiPoliciesPage'))),
  ApiPoliciesUuidPage:                        lazy(() => chunk(import('./pages/api/policies/uuid/ApiPoliciesUuidPage'))),
  ApiPolicyViolationsPage:                    lazy(() => chunk(import('./pages/api/policy_violations/ApiPolicyViolationsPage'))),
  ApiPolicyViolationsUuidPage:                lazy(() => chunk(import('./pages/api/policy_violations/uuid/ApiPolicyViolationsUuidPage'))),
  ApiCloudResourcesUuidPage:                  lazy(() => chunk(import('./pages/api/cloud_resources/uuid/ApiCloudResourcesUuidPage'))),
  ApiMobileApplicationLoadingPage:            lazy(() => chunk(import('./pages/api/mobile_applications/ApiMobileApplicationLoadingPage'))),
  ApiProtectionPage:                          lazy(() => chunk(import('./pages/api/toolkits/api_protect/ApiProtectPage'))),
  ApiProtectUuidPage:                         lazy(() => chunk(import('./pages/api/toolkits/api_protect/ApiProtectUuidPage'))),
  ApiShadowAssetsPage:                        lazy(() => chunk(import('./pages/api/shadow_assets/ApiShadowAssetsPage'))),
  CloudActivityPage:                          lazy(() => chunk(import('./pages/cloud/activity/CloudActivityPage'))),
  CloudAsmSetupPage:                          lazy(() => chunk(import('./pages/cloud/asm_setup/CloudAsmSetupPage'))),
  CloudAssetGroupsUuidPage:                   lazy(() => chunk(import('./pages/cloud/asset_groups/uuid/CloudAssetGroupsUuidPage'))),
  CloudCloudResourcesUuidPage:                lazy(() => chunk(import('./pages/cloud/cloud_resources/uuid/CloudCloudResourcesUuidPage'))),
  CloudInventoryPage:                         lazy(() => chunk(import('./pages/cloud/inventory/CloudInventoryPage'))),
  APINetworkServicesUuidPage:                 lazy(() => chunk(import('./pages/api/network_services/uuid/APINetworkServicesUuidPage.js'))),
  CloudNetworkServicesUuidPage:               lazy(() => chunk(import('./pages/cloud/network_services/uuid/CloudNetworkServicesUuidPage.js'))),
  WebNetworkServicesUuidPage:                 lazy(() => chunk(import('./pages/web/network_services/uuid/WebNetworkServicesUuidPage.js'))),
  CloudPage:                                  lazy(() => chunk(import('./pages/cloud/CloudPage'))),
  CloudAssetsUuidPage:                        lazy(() => chunk(import('./pages/cloud/assets/uuid/CloudAssetsUuidPage'))),
  CloudPoliciesPage:                          lazy(() => chunk(import('./pages/cloud/policies/CloudPoliciesPage'))),
  CloudPoliciesUuidPage:                      lazy(() => chunk(import('./pages/cloud/policies/uuid/CloudPoliciesUuidPage'))),
  CloudPolicyViolationsPage:                  lazy(() => chunk(import('./pages/cloud/policy_violations/CloudPolicyViolationsPage'))),
  CloudPolicyViolationsUuidPage:              lazy(() => chunk(import('./pages/cloud/policy_violations/uuid/CloudPolicyViolationsUuidPage'))),
  CloudShadowAssetsPage:                      lazy(() => chunk(import('./pages/cloud/shadow_assets/CloudShadowAssetsPage'))),
  CloudToolkitsCodeRedPage:                   lazy(() => chunk(import('./pages/cloud/toolkits/code_red/CodeRedPage'))),
  CloudToolkitsDlpPage:                       lazy(() => chunk(import('./pages/cloud/toolkits/dlp/CloudToolkitsDlpPage'))),
  CloudToolkitsGotchasPage:                   lazy(() => chunk(import('./pages/cloud/toolkits/gotchas/CloudToolkitsGotchasPage'))),
  CloudToolkitsLeaksPage:                     lazy(() => chunk(import('./pages/cloud/toolkits/leaks/CloudToolkitsLeaksPage'))),
  CloudToolkitsRansomwarePage:                lazy(() => chunk(import('./pages/cloud/toolkits/ransomware/RansomwarePage'))),
  CloudToolkitsAlphaSOCPage:                  lazy(() => chunk(import('./pages/cloud/toolkits/alpha_soc/CloudToolkitsAlphaSOCPage'))),
  CloudIacScanPage:                           lazy(() => chunk(import('./pages/cloud/iac_scan/CloudIacScanPage'))),
  ApolloCorrectAndProjectContainer:           lazy(() => chunk(import('./pages/cloud/toolkits/correct_protect/CorrectAndProtectPage'))),
  CloudToolkitsPage:                          lazy(() => chunk(import('./pages/cloud/toolkits/CloudToolkitsPage'))),
  CloudWebApplicationsUuid:                   lazy(() => chunk(import('./pages/cloud/web_applications/uuid/CloudWebApplicationsUuidPage'))),
  DashboardPage:                              lazy(() => chunk(import('./pages/dashboard/DashboardPage'))),
  MajorMalfunctionPage:                       lazy(() => chunk(import('./pages/cloud/toolkits/major_malfunction/MajorMalfunctionPage'))),
  ManagementSharePolicyViolationPage:         lazy(() => chunk(import('./pages/management/share/ManagementSharePolicyViolationPage'))),
  ApiWebApplicationsUuidPage:                 lazy(() => chunk(import('./pages/api/web_applications/uuid/ApiWebApplicationsUuidPage'))),
  WebApplicationsUuidPage:                    lazy(() => chunk(import('./pages/web/applications/uuid/WebApplicationsUuidPage'))),
  WebAsmSetupPage:                            lazy(() => chunk(import('./pages/web/asm_setup/WebAsmSetupPage'))),
  WebAssetGroupsUuidPage:                     lazy(() => chunk(import('./pages/web/asset_groups/uuid/WebAssetGroupsUuidPage'))),
  WebCloudResourcesUuidPage:                  lazy(() => chunk(import('./pages/web/cloud_resources/uuid/WebCloudResourcesUuidPage'))),
  WebGraphqlApisUuidPage:                     lazy(() => chunk(import('./pages/web/graphql_apis/uuid/WebGraphqlApisUuidPage'))),
  ApiGraphqlApisUuidPage:                     lazy(() => chunk(import('./pages/api/graphql_apis/uuid/ApiGraphqlApisUuidPage'))),
  CloudGraphqlApisUuidPage:                   lazy(() => chunk(import('./pages/cloud/graphql_apis/uuid/CloudGraphqlApisUuidPage'))),
  WebAssetsUuidPage:                          lazy(() => chunk(import('./pages/web/assets/uuid/WebAssetsUuidPage'))),
  WebInventoryPage:                           lazy(() => chunk(import('./pages/web/inventory/WebInventoryPage'))),
  WebPage:                                    lazy(() => chunk(import('./pages/web/WebPage'))),
  WebPoliciesPage:                            lazy(() => chunk(import('./pages/web/policies/WebPoliciesPage'))),
  WebPoliciesUuidPage:                        lazy(() => chunk(import('./pages/web/policies/uuid/WebPoliciesUuidPage'))),
  WebPolicyViolationsPage:                    lazy(() => chunk(import('./pages/web/policy_violations/WebPolicyViolationsPage'))),
  WebPolicyViolationsUuidPage:                lazy(() => chunk(import('./pages/web/policy_violations/uuid/WebPolicyViolationsUuidPage'))),
  WebSecurityToolkitsDetectInjectPage:        lazy(() => chunk(import('./pages/web/security_toolkits/sql_injection/WebSecurityToolkitsSqlInjectionPage'))),
  WebSecurityToolkitsPage:                    lazy(() => chunk(import('./pages/web/security_toolkits/WebSecurityToolkitsPage'))),
  WebSecurityToolkitsXssAttackPage:           lazy(() => chunk(import('./pages/web/security_toolkits/xss_attack/WebSecurityToolkitsXssAttackPage'))),
  WebSecurityToolkitsXssProtectionPage:       lazy(() => chunk(import('./pages/web/security_toolkits/xss_protection/WebSecurityToolkitsXssProtectionPage'))),
  WebSecurityToolkitsWebProtectPage:          lazy(() => chunk(import('./pages/web/security_toolkits/web_protect/WebSecurityToolkitsWebProtectPage'))),
  WebShadowAssetsPage:                        lazy(() => chunk(import('./pages/web/shadow_assets/WebShadowAssetsPage'))),
  WebToxicTokensPage:                         lazy(() => chunk(import('./pages/web/toxic_tokens/WebToxicTokensPage'))),
  SupplyChainPage:                            lazy(() => chunk(import('./pages/supply_chain/supplyChainPage'))),
  SupplyChainSBOMPage:                        lazy(() => chunk(import('./pages/supply_chain/sbom/SBOMPage'))),
  SupplyChainVendorsPage:                     lazy(() => chunk(import('./pages/supply_chain/vendors/VendorsPage'))),
  SupplyChainMyAppsPage:                      lazy(() => chunk(import('./pages/supply_chain/my_apps/MyAppsPage'))),
  SupplyChainAsmSetupPage:                    lazy(() => chunk(import('./pages/supply_chain/asm_setup/SupplyChainAsmSetupPage'))),
  SupplyChainSbomSecurityIssuesPage:          lazy(() => chunk(import('./pages/supply_chain/security_issues/SupplyChainSecurityIssuesPage'))),

  // {{ TODO: These need to be broken out into separate pages.
  AtlasPublicAppSecurity:                     lazy(() => chunk(import('./redux/atlas/components/SharedLinks/PublicAppSecurity'))),
  AtlasConfiguration:                         lazy(() => chunk(import('./redux/atlas/components/Configuration'))),
  AtlasApplicationView:                       lazy(() => chunk(import('./redux/atlas/components/ApplicationView'))),
  AtlasSearchView:                            lazy(() => chunk(import('./redux/atlas/components/SearchView'))),
  ApolloActivityDashboardContainer:           lazy(() => chunk(import('./redux/events/ActivityDashboardContainer'))),
  ApolloApiOperationDetailsContainer:         lazy(() => chunk(import('./redux/api_operations/ApiOperationDetailsContainer'))),
  ApolloApiProtectContainer:                  lazy(() => chunk(import('./redux/protect/ApiProtectContainer'))),
  ApolloDetectInjectContainer:                lazy(() => chunk(import('./redux/detect_inject/DetectInjectContainer'))),
  ApolloHackExtractContainer:                 lazy(() => chunk(import('./redux/hack_extract/HackExtractContainer'))),
  ApolloLeakyApisContainer:                   lazy(() => chunk(import('./redux/leaky_apis/LeakyApisContainer'))),
  ApolloNetworkServiceDetailsContainer:       lazy(() => chunk(import('./redux/network_services/NetworkServiceDetailsContainer'))),
  ApolloOverview:                             lazy(() => chunk(import('./redux/dashboard/Overview'))),
  ApolloOverviewDetail:                       lazy(() => chunk(import('./redux/dashboard/OverviewDetail'))),
  ApolloPolicyRuleWithViolationsAndDetails:   lazy(() => chunk(import('./redux/policy_rules/PolicyRuleWithViolationsAndDetails'))),
  ApolloRestfulApiDetailsContainer:           lazy(() => chunk(import('./redux/restful_apis/RestfulAPIDetailsContainer'))),
  ApolloSharedLinks:                          lazy(() => chunk(import('./redux/shared_links/SharedLinks'))),
  ApolloSsrfSploitsContainer:                 lazy(() => chunk(import('./redux/ssrf_sploits/SsrfSploitsContainer'))),
  ManagementActivity:                         lazy(() => chunk(import('./pages/management/activity/ActivityPage'))),
  ManagementComplianceReports:                lazy(() => chunk(import('./redux/compliance_reports/ComplianceReports'))),
  ManagementLandingPage:                      lazy(() => chunk(import('@dt/landing-page/LandingPage'))),
  ManagementProductOnboardingContainer:       lazy(() => chunk(import('./redux/onboarding_wizard/ProductOnboardingContainer'))),
  ManagementProfile:                          lazy(() => chunk(import('./redux/profile/Profile'))),
  ManagementUserDashboard:                    lazy(() => chunk(import('./redux/users/UserDashboard'))),
  ManagementUserEdit:                         lazy(() => chunk(import('./redux/users/UserEdit'))),
  ManagementUserInvite:                       lazy(() => chunk(import('./redux/users/UserInvite'))),
  ManagementVendorManagementContainer:        lazy(() => chunk(import('./pages/management/scs/VendorPage'))),
  OnboardingWizardPage:                       lazy(() => chunk(import('./pages/management/onboarding_wizard/OnboardingWizardPage'))),
  OnboardingWizardPublicViewPage:             lazy(() => chunk(import('./pages/management/onboarding_wizard/OnboardingWizardPublicViewPage'))),
  ShareCloudResourcePublicView:               lazy(() => chunk(import('./redux/shared_links/public_app/CloudResourcePublicView'))),
  ShareNetworkServicesPublicView:             lazy(() => chunk(import('./redux/shared_links/public_app/NetworkServicePublicView'))),
  ShareOnboardingPublicView:                  lazy(() => chunk(import('./redux/shared_links/public_app/OnboardingPublicView'))),
  ShareProductOnboardingWizardPublicView:     lazy(() => chunk(import('./redux/shared_links/public_app/ProductOnboardingWizardPublicView'))),
  ShareRestfulAPIPublicView:                  lazy(() => chunk(import('./redux/shared_links/public_app/RestfulAPIPublicView'))),
  ShareWebAppPublicView:                      lazy(() => chunk(import('./redux/shared_links/public_app/WebAppPublicView'))),
  // }}

  // Mobile secure
  MobilePoliciesPage:                          lazy(() => chunk(import('./pages/mobile/policies/MobilePoliciesPage'))),
  MobilePoliciesUuidPage:                      lazy(() => chunk(import('./pages/mobile/policies/uuid/MobilePoliciesUuidPage'))),
  MobileInventoryPage:                         lazy(() => chunk(import('./pages/mobile/inventory/MobileInventoryPage'))),
  MobileAssetGroupsUuidPage:                   lazy(() => chunk(import('./pages/mobile/asset_groups/uuid/MobileAssetGroupsUuidPage'))),

  SlackMicrosoftTeamsIntegrationPage:               lazy(() => chunk(import('./pages/management/sdlc/slack_msft/SlackMicrosoftTeamsIntegrationPage'))),
  SamlIntegrationPage:                              lazy(() => chunk(import('./pages/management/sdlc/SamlIntegrationPage'))),
  WebhookIntegrationPage:                           lazy(() => chunk(import('./pages/management/sdlc/webhooks/WebhookIntegrationListPage'))),

  // Shared policy violations
  SharedPolicyViolations:                      lazy(() => chunk(import('./pages/management/policy_violations/SharedPolicyViolations'))),

  // DevSecOps
  DevSecOpsPage:                               lazy(() => chunk(import('./pages/devsecops/DevSecOpsHome'))),
  StanfordDishUniHome:                         lazy(() => chunk(import('./pages/devsecops/stanford_dish_university/StanfordDishUniHome'))),
  StanfordDishUniViewAllMaterials:             lazy(() => chunk(import('./pages/devsecops/stanford_dish_university/StanfordDishUniViewAllMaterials'))),
  DevSecOpsJiraApiCloudWebLandingPage:         lazy(() => chunk(import('./pages/devsecops/jira_integration/api_cloud_web/ApiCloudWebLandingPage'))),
  DevSecOpsJiraApiCloudWebConfigure:           lazy(() => chunk(import('./pages/devsecops/jira_integration/api_cloud_web/ApiCloudWebConfigure'))),

  // SCS Vendor
  VendorUuidPage:                              lazy(() => chunk(import('./pages/management/scs/uuid/VendorUuidPage'))),
};

const useStyles = makeStyles({
  root: {
    height: '100%',
    display: 'flex',
  },
  content: {
    overflow: 'auto',
    flexGrow: '1',
    minHeight: '100%',
    backgroundColor: ({ pathname }) => {
      // NOTE: HACK: If you add more code here I will find you... and buy you a coffee.

      // TODO: All API Pages need to be updated with the new gray background.
      if (pathname.indexOf('/api') === 0) {
        // Pages that have been ported.
        if (
          pathname.indexOf('/api/network-service') === -1 &&
          pathname.indexOf('/api/web-applications') === -1 &&
          pathname.indexOf('/api/restful-apis') === -1 &&
          pathname.indexOf('/api/cloud-resources') === -1 &&
          pathname.indexOf('/api/asm-setup') !== 0 &&
          pathname.indexOf('/api/share') !== 0 &&
          pathname.indexOf('/api/policies') !== 0 &&
          pathname.indexOf('/api/asset-groups') !== 0 &&
          pathname.indexOf('/api/inventory') !== 0 &&
          pathname.indexOf('/api/assets') !== 0 &&
          pathname.indexOf('/api/policy-violations') !== 0 &&
          pathname.indexOf('/api/shadow-assets') !== 0
        ) {
          return palette.white;
        }
      }

      return palette.gray50;
    },
  },
});

/*
 * Wraps the left hand menu with user session information.
 *
 * If no session is found, don't render.
 * This is primarily used to prevent issues for actors with temporary session tokens.
 *
 * TODO: Push down into menu after all apps have been merged.
 *
 * @deprecated - Onboarding banner needs to be moved into this application.
 */
function ConfigurableLHSMenu(props) {
  if (props.loading || !props.data)
    return <ConfigurableLHSMenuComponent isLoading />;

  // $FlowFixMe - Use the same props.
  return <ConfigurableLHSMenuComponent {...props} />;
}

/*
 * Wraps the onboarding banner with user session information.
 *
 * If no session is found, don't render.
 * This is primarily used to prevent issues for actors with temporary session tokens.
 *
 * TODO: Push down into banner after all apps have been merged.
 *
 * @deprecated - Onboarding banner needs to be moved into this application.
 */
function OnboardingBanner({ loading, data }) {
  if (loading || !data) return null;

  return (
    <OnboardingBannerComponent
      accountInfo={data.user_account.accountInfo}
      fullScreen
    />
  );
}

function ApplicationRouting() {
  // TODO: Should *not* be checking path outside of the router.
  const { loading, data } = useSession({ unauthenticatedRedirect: false });
  const location = useLocation();
  const pathname = location.pathname;
  const isSharedPath = location.pathname.indexOf('/share') !== -1;

  const styles = useStyles({ pathname });

  const dispatch = useDispatch();

  // TODO: Remove this in favor of pushing the logic down into the Notifier component.
  const dispatchDismissNotificationClicked = useCallback(
    notification => {
      dispatch(NotificationsActions.dismissNotificationClicked(notification));
    },
    [dispatch],
  );
  const notifications = useSelector<State, _>(
    ({ notifications }) => notifications,
  );

  // Page Title.
  useEffect(() => {
    let title = null;
    if (pathname.startsWith('/api')) {
      title = 'API Secure';
    } else if (pathname.startsWith('/cloud')) {
      title = 'Cloud Secure';
    } else if (pathname.startsWith('/dashboard')) {
      title = 'Dashboard';
    } else if (pathname.startsWith('/management')) {
      title = 'Management';
    } else if (pathname.startsWith('/mobile')) {
      title = 'Mobile Secure';
    } else if (pathname.startsWith('/supply-chain')) {
      title = 'Supply Chain Secure';
    } else if (pathname.startsWith('/openscan')) {
      title = 'Supply Chain Security';
    } else if (pathname.startsWith('/web')) {
      title = 'Web Secure';
    } else if (pathname.startsWith('/devsecops')) {
      title = 'DevSecOps';
    }

    document.title = `${title ? `${title} | ` : ''}Data Theorem`;
  }, [pathname]);

  // Page Menu.
  const routesSidebarContent = [
    ['/api', MenuApi],
    ['/cloud', MenuCloud],
    ['/dashboard', MenuDashboard],
    // TODO: This needs to be moved to a path that doesn't conflict with the management route.
    //       Maybe `/share`?
    //       This does *not* follow the router pathing matches bc of this ^.
    ['/management/share', null],
    ['/management/onboarding', null],
    [
      '/management/scs',
      MenuScs({
        accountInfo: data?.user_account.accountInfo,
        currentUser: data?.user_account.currentUser,
      }),
    ],
    ['/management', MenuManagement],
    ['/mobile-secure', MenuMobile({ isMobileSecure: true })],
    ['/devsecops', MenuDevSecOps],
    ['/mobile', MenuMobile({ isMobileSecure: false })],
    ['/supply-chain', MenuSupplyChainSecure],
    ['/openscan', MenuSupplyChainSecurity],
    ['/web', MenuWeb],
  ];

  // Banner Content.
  // prettier-ignore
  const routesBannerContent = [
    ['/api',            <OnboardingBanner loading={loading} data={data} key='api' />],
    ['/cloud',          <OnboardingBanner loading={loading} data={data} key='cloud' />],
    ['/dashboard',      <OnboardingBanner loading={loading} data={data} key='cloud' />],
    ['/management',     null],
    ['/mobile-secure',  <OnboardingBanner loading={loading} data={data} key='mobile-secure' />],
    ['/devsecops',      null],
    ['/mobile',         <OnboardingBanner loading={loading} data={data} key='mobile' />],
    ['/supply-chain',   <OnboardingBanner loading={loading} data={data} key='supply-chain' />],
    ['/openscan',       <OnboardingBanner loading={loading} data={data} key='openscan' />],
    ['/web',            <OnboardingBanner loading={loading} data={data} key='web' />],
  ];

  // Page Content.
  // prettier-ignore
  const routesPageContent = {
    // TODO: Support for stripping trailing slashes.
    //'.*/+$':                                             window.location.pathname.slice(0, -1),
    //
    // TODO: Support for client side Page authorization.
    //       Currently a subset of these pages will show a <CenteredCircularProgress />
    //       These pages need to be updated to show their corresponding Page Skeleton and not the
    //       Circular progress spinner.
    //
    '/api':                                                      '/api/overview',
    '/api/assets/:id':                                           '/api/assets/:id/overview',
    '/api/assets/:id/:currentTab':                               ApiAssetsUuidPage,
    '/api/asm-setup':                                            ApiAsmSetupPage,
    '/api/asset-groups/:id':                                     ApiAssetGroupsUuidPage,
    '/api/custom-checks':                                        ApiCustomChecksPage,
    '/api/discover/shadow-assets':                               '/api/shadow-assets',
    '/api/discover/configuration':                               '/api/asm-setup',
    '/api/discover/inventory':                                   '/api/inventory',
    '/api/discover/inventory/:currentTab':                       '/api/inventory/:currentTab',
    '/api/inspect/policy-violations':                            '/api/policy-violations',
    '/api/inspect/policy-violations/:id':                        '/api/policy-violations/:id',
    '/api/inventory':                                            '/api/inventory/api-assets',
    '/api/inventory/inventory':                                  '/api/inventory/api-assets',
    '/api/inventory/:currentTab':                                ApiInventoryPage,
    '/api/policies':                                             ApiPoliciesPage,
    '/api/policies/:id':                                         '/api/policies/:id/api',
    '/api/policies/:id/:currentTab':                             ApiPoliciesUuidPage,
    '/api/policy':                                               '/api/policies',
    '/api/policy/:id':                                           '/api/policies/:id',
    '/api/policy-violations':                                    ApiPolicyViolationsPage,
    '/api/policy-violations/:id':                                ApiPolicyViolationsUuidPage,
    '/api/protect':                                              '/api/protect/overview',
    '/api/protect/uuid/:id':                                    '/api/protect/uuid/:id/overview',
    '/api/protect/uuid/:id/:currentTab':                         ApiProtectUuidPage,
    '/api/protect/:currentTab':                                  ApiProtectionPage,
    '/api/mobile_applications/:id':                              ApiMobileApplicationLoadingPage,
    '/api/shadow-assets':                                        ApiShadowAssetsPage,
    '/api/graphql-apis/:id':                                     ApiGraphqlApisUuidPage,
    '/api/graphql-apis/:id/:currentTab':                         ApiGraphqlApisUuidPage,
    '/api/restful-apis/:id':                                     '/api/assets/:id/overview',
    '/api/restful-apis/:id/:currentTab':                         '/api/assets/:id/:currentTab',
    '/cloud':                                                    CloudPage,
    '/cloud/assets/:id':                                         '/cloud/assets/:id/overview',
    '/cloud/assets/:id/:currentTab':                             CloudAssetsUuidPage,
    '/cloud/activity':                                           CloudActivityPage,
    '/cloud/asm-setup':                                          CloudAsmSetupPage,
    '/cloud/asset-groups/:id':                                   CloudAssetGroupsUuidPage,
    '/cloud/cloud-resources/:id':                                CloudCloudResourcesUuidPage,
    '/cloud/cloud-resources/:id/:currentTab':                    CloudCloudResourcesUuidPage,
    '/cloud/inventory':                                          '/cloud/inventory/cloud-assets',
    '/cloud/inventory/inventory':                                '/cloud/inventory/cloud-assets',
    '/cloud/inventory/:currentTab':                              CloudInventoryPage,
    '/cloud/network-services/:id':                               CloudNetworkServicesUuidPage,
    '/cloud/network-services/:id/:currentTab':                   CloudNetworkServicesUuidPage,
    '/cloud/policies':                                           CloudPoliciesPage,
    '/cloud/iac-scan':                                           CloudIacScanPage,
    '/cloud/policies/:id':                                       '/cloud/policies/:id/cloud',
    '/cloud/policies/:id/:currentTab':                           CloudPoliciesUuidPage,
    '/cloud/policies/:id/custom-checks/:customCheckId':          CloudPoliciesUuidPage,
    '/cloud/policy-violations':                                  CloudPolicyViolationsPage,
    '/cloud/policy-violations/:id':                              CloudPolicyViolationsUuidPage,
    '/cloud/security-toolkits':                                  CloudToolkitsPage,
    '/cloud/security-toolkits/cloud-gotchas':                    CloudToolkitsGotchasPage,
    '/cloud/security-toolkits/cloud-gotchas/:currentTab':        CloudToolkitsGotchasPage,
    '/cloud/security-toolkits/cloud-leaks':                      CloudToolkitsLeaksPage,
    '/cloud/security-toolkits/code-red':                         CloudToolkitsCodeRedPage,
    '/cloud/security-toolkits/dlp':                              '/cloud/security-toolkits/dlp/overview',
    '/cloud/security-toolkits/dlp/:currentTab':                  CloudToolkitsDlpPage,
    '/cloud/security-toolkits/correct-and-protect':              ApolloCorrectAndProjectContainer,
    '/cloud/security-toolkits/major-malfunction':                MajorMalfunctionPage,
    '/cloud/security-toolkits/ransomware':                       '/cloud/security-toolkits/ransomware/overview',
    '/cloud/security-toolkits/ransomware/:currentTab':           CloudToolkitsRansomwarePage,
    '/cloud/security-toolkits/alpha-soc':                        CloudToolkitsAlphaSOCPage,
    // NOTE:                                                     DO NOT do this inlining - Legacy only.
    //                                                           $FlowFixMe - Inlined components don't work with chunk import.
    '/cloud/security-toolkits/correct-and-protect/:id':          accessControlsApi(({ id }) => <ApolloPolicyRuleWithViolationsAndDetails id={id} is_eligible_for_auto_remediation={true} filter_by_violation_status={['OPEN']} />),
    '/cloud/shadow-assets':                                      CloudShadowAssetsPage,
    '/cloud/web-applications/:id':                               CloudWebApplicationsUuid,
    '/cloud/web-applications/:id/:currentTab':                   CloudWebApplicationsUuid,
    '/cloud/graphql-apis/:id':                                   CloudGraphqlApisUuidPage,
    '/cloud/graphql-apis/:id/:currentTab':                       CloudGraphqlApisUuidPage,
    '/dashboard':                                                DashboardPage,
    '/management':                                               '/management/products',
    '/management/share/:token/violations/:id':                   '/management/share/:token/policy-violations/:id',
    '/management/share/:token/policy-violations/:id':            ManagementSharePolicyViolationPage,
    '/web/':                                                     WebPage,
    '/web/asm-setup':                                            WebAsmSetupPage,
    '/web/assets/:id':                                           '/web/assets/:id/overview',
    '/web/assets/:id/:currentTab':                               WebAssetsUuidPage,
    '/web/asset-groups/:id':                                     WebAssetGroupsUuidPage,
    '/web/cloud-resources/:id':                                  WebCloudResourcesUuidPage,
    '/web/cloud-resources/:id/:currentTab':                      WebCloudResourcesUuidPage,
    '/web/graphql-apis/:id':                                     WebGraphqlApisUuidPage,
    '/web/graphql-apis/:id/:currentTab':                         WebGraphqlApisUuidPage,
    '/web/inventory':                                            '/web/inventory/web-assets',
    '/web/inventory/inventory':                                  '/web/inventory/web-assets',
    '/web/inventory/web-apps':                                   '/web/inventory/web-assets',
    '/web/inventory/:currentTab':                                WebInventoryPage,
    '/web/network-services/:id':                                 WebNetworkServicesUuidPage,
    '/web/network-services/:id/:currentTab':                     WebNetworkServicesUuidPage,
    '/web/policies':                                             WebPoliciesPage,
    '/web/policies/:id':                                         '/web/policies/:id/web',
    '/web/policies/:id/:currentTab':                             WebPoliciesUuidPage,
    '/web/policy-violations':                                    WebPolicyViolationsPage,
    '/web/policy-violations/:id':                                WebPolicyViolationsUuidPage,
    '/web/security-toolkits':                                    WebSecurityToolkitsPage,
    '/web/security-toolkits/sql-injection/*':                    WebSecurityToolkitsDetectInjectPage,
    '/web/security-toolkits/xss-attack':                         WebSecurityToolkitsXssAttackPage,
    '/web/security-toolkits/xss-attack/:currentTab':             WebSecurityToolkitsXssAttackPage,
    '/web/security-toolkits/xss-protection':                     WebSecurityToolkitsXssProtectionPage,
    '/web/security-toolkits/web-protect':                        WebSecurityToolkitsWebProtectPage,
    '/web/shadow-assets':                                        WebShadowAssetsPage,
    '/web/toxic-tokens/*':                                       WebToxicTokensPage,
    '/web/web-applications/:id':                                 WebApplicationsUuidPage,
    '/web/web-applications/:id/:currentTab':                     WebApplicationsUuidPage,
    '/supply-chain':                                             SupplyChainPage,
    '/supply-chain/sbom':                                        SupplyChainSBOMPage,
    '/supply-chain/vendors':                                     SupplyChainVendorsPage,
    '/supply-chain/my-apps':                                     SupplyChainMyAppsPage,
    '/supply-chain/asm-setup':                                   SupplyChainAsmSetupPage,
    '/supply-chain/sbom-security-issues':                        SupplyChainSbomSecurityIssuesPage,

    // {{ TODO: Port over to Pages.
    '/api/activity':                                             accessControlsApi(ApolloActivityDashboardContainer),
    '/api/api-operations/:id':                                   accessControlsApi(ApolloApiOperationDetailsContainer),
    '/api/cloud-resources/:id':                                  ApiCloudResourcesUuidPage,
    '/api/cloud-resources/:id/:currentTab':                      ApiCloudResourcesUuidPage,
    '/api/inspect/detect-and-inject':                            accessControlsApi(ApolloDetectInjectContainer),
    '/api/inspect/hack-and-extract':                             accessControlsApi(ApolloHackExtractContainer),
    '/api/inspect/leaky-apis':                                   accessControlsApi(ApolloLeakyApisContainer),
    '/api/inspect/policy-rules/:id':                             accessControlsApi(ApolloPolicyRuleWithViolationsAndDetails),
    '/api/inspect/policy-rules/:id/:policy_violation_id':        accessControlsApi(ApolloPolicyRuleWithViolationsAndDetails),
    '/api/network-services/:id':                                 APINetworkServicesUuidPage,
    '/api/network-services/:id/:currentTab':                     APINetworkServicesUuidPage,
    '/api/overview':                                             accessControlsApi(ApolloOverview),
    '/api/overview/detail':                                      accessControlsApi(ApolloOverviewDetail),
    '/api/share/:token/cloud-resources/:id':                     '/management/share/:token/cloud-resources/:id',
    '/api/share/:token/cloud-resources/:id/:currentTab':         '/management/share/:token/cloud-resources/:id/:currentTab',
    '/api/share/:token/network-services/:id':                    '/management/share/:token/network-services/:id',
    '/api/share/:token/network-services/:id/:currentTab':        '/management/share/:token/network-services/:id/:currentTab',
    '/api/share/:token/onboard':                                 '/management/share/:token/onboard',
    '/api/share/:token/onboarding':                              '/management/share/:token/onboarding',
    '/api/share/:token/restful-apis/:id':                        '/management/share/:token/restful-apis/:id',
    '/api/share/:token/restful-apis/:id/:currentTab':            '/management/share/:token/restful-apis/:id/:currentTab',
    '/api/share/:token/violations/:id':                          '/management/share/:token/violations/:id',
    '/api/share/:token/web-applications/:id':                    '/management/share/:token/web-applications/:id',
    '/api/share/:token/web-applications/:id/:currentTab':        '/management/share/:token/web-applications/:id/:currentTab',
    '/api/shared-links':                                         accessControlsApi(ApolloSharedLinks),
    '/api/ssrf-sploits-toolkit':                                 accessControlsApi(ApolloSsrfSploitsContainer),
    '/api/web-applications/:id':                                 ApiWebApplicationsUuidPage,
    '/api/web-applications/:id/:currentTab':                     ApiWebApplicationsUuidPage,
    '/management/compliance-report':                             accessControlsApi(ManagementComplianceReports),
    '/management/onboarding':                                    accessControlsApi(ManagementProductOnboardingContainer),
    '/management/products':                                      accessControlsLandingPage(ManagementLandingPage),
    '/management/profile':                                       accessControlsApi(ManagementProfile),
    '/management/share/:token/cloud-resources/:id':              accessControlsShare(ShareCloudResourcePublicView),
    '/management/share/:token/cloud-resources/:id/:currentTab':  accessControlsShare(ShareCloudResourcePublicView),
    '/management/share/:token/network-services/:id':             accessControlsShare(ShareNetworkServicesPublicView),
    '/management/share/:token/network-services/:id/:currentTab': accessControlsShare(ShareNetworkServicesPublicView),
    '/management/share/:token/openscan/apps/:appId':             '/management/share/:token/openscan/apps/:appId/metrics',
    '/management/share/:token/openscan/apps/:appId/:currentTab': AtlasPublicAppSecurity,
    //    NOTE: These two 'onboarding' routes are subtly different.
    //          The '/management/share/:token/onboarding' is for cross product onboarding
    //          - Mobile Apps
    //          - Cloud Environments
    //          - Jira
    //          - Slack
    //          The '/management/share/:token/onboard' is the legacy implementation for onboarding
    //          - Cloud Resources
    //          - API Gateways
    '/management/share/:token/onboard':                          accessControlsShare(ShareOnboardingPublicView),
    '/management/share/:token/onboarding':                       accessControlsShare(ShareProductOnboardingWizardPublicView),
    '/management/share/:token/restful-apis/:id':                 accessControlsShare(ShareRestfulAPIPublicView),
    '/management/share/:token/restful-apis/:id/:currentTab':     accessControlsShare(ShareRestfulAPIPublicView),
    '/management/share/:token/web-applications/:id':             accessControlsShare(ShareWebAppPublicView),
    '/management/share/:token/web-applications/:id/:currentTab': accessControlsShare(ShareWebAppPublicView),

    '/management/onboarding-wizard':                                  OnboardingWizardPage,
    '/management/onboarding-wizard/:currentStep':                     OnboardingWizardPage,

    '/management/share/:token/onboarding-wizard':                                  OnboardingWizardPublicViewPage,
    '/management/share/:token/onboarding-wizard/:currentStep':                     OnboardingWizardPublicViewPage,

    '/management/sdlc/slack-msft':                               SlackMicrosoftTeamsIntegrationPage,
    '/management/sdlc/saml':                                     SamlIntegrationPage,
    '/management/sdlc/webhooks':                                 WebhookIntegrationPage,

    // TODO: (rr) Update these to pages for POR-2899
    '/management/users':                                         userManagementControlsApi(ManagementUserDashboard),
    '/management/users/:userId':                                 userManagementControlsApi(ManagementUserEdit),
    '/management/users/invite':                                  userManagementControlsApi(ManagementUserInvite),
    '/management/scs':                                           ManagementVendorManagementContainer,
    '/management/scs/vendor/:id':                                '/management/scs/vendor/:id/overview',
    '/management/scs/vendor/:id/:currentTab':                    VendorUuidPage,
    '/openscan/share/:token/app/:appId':                         '/management/share/:token/openscan/apps/:appId/metrics',
    '/openscan/share/:token/app/:appId/metrics':                 '/management/share/:token/openscan/apps/:appId/metrics',
    '/openscan/configure':                                       AtlasConfiguration,
    '/openscan/apps/:appId':                                     '/openscan/apps/:appId/metrics',
    '/openscan/apps/:appId/:currentTab':                         AtlasApplicationView,
    '/openscan':                                                 '/openscan/search',
    '/openscan/search':                                          AtlasSearchView,
    '/openscan/search/:appName':                                 AtlasSearchView,

    // Mobile secure
    '/mobile-secure/policies':                                   MobilePoliciesPage,
    '/mobile-secure/policies/:id':                               '/mobile-secure/policies/:id/mobile',
    '/mobile-secure/policies/:id/:currentTab':                   MobilePoliciesUuidPage,
    '/mobile-secure/inventory':                                  '/mobile-secure/inventory/mobile-apps',
    '/mobile-secure/inventory/:currentTab':                      MobileInventoryPage,
    '/mobile-secure/asset-groups/:id':                           MobileAssetGroupsUuidPage,
    '/mobile-secure/custom-checks':                              ApiCustomChecksPage,

    // Activity
    '/management/activity/:search':                              ManagementActivity,
    '/management/activity':                                      ManagementActivity,

    // Shared policy violations
    '/management/share/:scopedAccessToken/policy-violations':    SharedPolicyViolations,
    '/management/share/:scopedAccessToken/policy-violations/details/:id':SharedPolicyViolations,
    
    // DevSecOps
    '/devsecops': DevSecOpsPage,
    '/devsecops/stanford-dish-security-university': StanfordDishUniHome,
    '/devsecops/stanford-dish-security-university/materials': StanfordDishUniViewAllMaterials,
    '/devsecops/integrations/jira': DevSecOpsJiraApiCloudWebLandingPage,
    '/devsecops/integrations/jira/configure': DevSecOpsJiraApiCloudWebConfigure,
    '/devsecops/integrations/jira/configure/:integrationId': DevSecOpsJiraApiCloudWebConfigure,
    // }}
  };

  // TODO: Should *not* be checking path outside of the router.
  const menu = routesSidebarContent.find(config => {
    const regexp = new RegExp(`${config[0]}/`, 'gi');
    const isMatch = pathname.match(regexp);
    if (!isMatch) {
      const regexpNoTrailing = new RegExp(`${config[0]}`, 'gi');
      const isMatchNoTrailing = pathname.match(regexpNoTrailing);
      return isMatchNoTrailing;
    }
    return isMatch;
  });

  const banner = routesBannerContent.find(config => {
    const regexp = new RegExp(`${config[0]}/`, 'gi');
    const isMatch = pathname.match(regexp);
    if (!isMatch) {
      const regexpNoTrailing = new RegExp(`${config[0]}`, 'gi');
      const isMatchNoTrailing = pathname.match(regexpNoTrailing);
      return isMatchNoTrailing;
    }
    return isMatch;
  });

  return (
    <div className={styles.root}>
      {/* Sidebar */}
      <Router style={{ height: '100%', zIndex: 1150 }} data-testid="pageMenu">
        {menu && menu[1] ? (
          <ConfigurableLHSMenu
            loading={loading}
            data={data}
            path={`${menu[0]}/*`}
            configuration={menu[1]}
          />
        ) : null}
      </Router>

      {/* Page */}
      <div className={styles.content} data-testid="pageContent">
        <Suspense fallback={null}>
          {banner && banner[1] ? banner[1] : null}

          <Router tabIndex={'none'} style={{ height: '100%' }}>
            {entries(
              routesPageContent,
            ).map(
              (
                [route, Destination]: [
                  string,
                  string | React$AbstractComponent<{| +path: string |}, mixed>,
                ],
                i,
              ) =>
                typeof Destination === 'string' ? (
                  <Redirect key={i} from={route} to={Destination} noThrow />
                ) : (
                  <Destination key={i} path={route} />
                ),
            )}

            {/* TODO: Move to pages after apps are merged. */}
            <NotFoundPage default />
          </Router>

          {!isSharedPath && <BillingReminderSnack />}

          <Notifier
            notifications={notifications}
            onDismissClick={dispatchDismissNotificationClicked}
          />
        </Suspense>
      </div>
    </div>
  );
}

export default memo<{||}>(ApplicationRouting);
